














/**
 * Created by wangtong on 2022/2/4
 */
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import MobileHome from "@/components/home/MobileHome.vue";
import PcHome from "@/components/home/PcHome.vue";
import SplatMobileHome from "@/components/home/SplatMobileHome.vue";
import SplatPcHome from "@/components/home/SplatPcHome.vue";
import BasePage from "@/mixins/BasePage";
import { isLocaleEN, isLocaleZH } from "@/utils";

@Component({
  components: {
    MobileHome,
    PcHome,
    SplatPcHome,
    SplatMobileHome,
  },
})
export default class Home extends BasePage {
  created() {
    super.created();
    // console log current locale from env
    isLocaleEN() && console.log("current locale is en");
  }

  get localeEN() {
    return isLocaleEN();
  }

  get localeZH() {
    return isLocaleZH();
  }
}
