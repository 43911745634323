







/**
 * Created by wangtong on 2021/12/11
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import SplatMobileHomeBackground from "@/components/home/SplatMobileHomeBackground.vue";
import SplatMobileAppDownloader from "@/components/app_downloader/SplatMobileAppDownloader.vue";
import {OfficialWebsiteQuery} from "@/models";

@Component({
    components: {SplatMobileAppDownloader, SplatMobileHomeBackground}
})
export default class SplatMobileHome extends Vue {
    @Prop() query?: OfficialWebsiteQuery
}
